<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from './../../../helpers/errorHandler';
import Swal from 'sweetalert2';
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import userService from '../../../helpers/userService';

/**
 * Starter component
 */
export default {
  page: {
    title: "Redeem code",
  },
  head() {
    return {
      title: `Redeem code`,
    };
  },
  filters:{
    formatDate(value){
        if(value){
            return moment(String(value)).format('hh:mm A DD/MM/YYYY')
        }
    }
  },
  data() {
    return {
      recordId: null,
      title: "Redeem code",
      items: [
        {
          text: "Manage",
          href: "/home",
        },
        {
          text: "Redeem code",
          href: "/redeem-code",
        },
        {
          text: "Code",
          active: true,
        },
      ],
      code: "",
      center: null,
      balance: null,
      submitted: false,
      loading: false,
      centerError: null,
      totalRows:0,
      perPage: 20,
      currentPage: 1,
      pageOptions: [10, 20, 30],
      filter: "",
      sortBy: "balance_codes",
      sortDesc: false,
      filterOn: ["balance_codes"],
      fields: [
        {
          key: "balance_codes",
          label: "Code - Balance",
          sortable: true,
          selected: true
        },
        {
          key: "code_dropdown",
          label: "Buy from",
          sortable: true,
          selected: true
        },
        {
          key: "timestamp",
          label: "Use time",
          sortable: true,
          selected: true
        },
      ]
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  methods:{
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async tryToLink() {
      this.submitted = true;
      this.loading = true;
      this.$v.$touch();
      if (this.$v.$invalid && !this.validate()) {
        this.loading = false;
        return;
      } else {
        await this.$store.dispatch("balance/redeemCode", {code:this.code.trim(),id:this.center}).then( async token =>{
            Swal.fire({
              icon: 'success',
              title: "Success",
              text: "Code redeemed successfully",
              confirmButtonClass: "btn btn-confirm mt-2",
              showConfirmButton: true,
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              allowEscapeKey: false,
              });
              await this.$store.dispatch('user/getProfile').then( res =>{
                    this.balance = res.student_data.balance;
                }).catch( err =>{
                    errorHandler.methods.error(err);
                });
              await this.$store.dispatch('balance/getBalanceLogs',userService.methods.userID()).then( res =>{

              }).catch( err =>{
                  errorHandler.methods.errorMessage(err);
              });
            }).catch((error) => {
            errorHandler.methods.errorMessage(error);
        });
        this.loading = false;
      }
    },
    clearSelectError2(){
      this.centerError = null;
    },
    validate(){
      if(this.center){
        this.centerError = true;
      }else{
        this.centerError = false;
      }
      return this.centerError;
    },
  },
  validations: {
    code: {
      required,
    },
    center: {
      required,
    },
  },
  computed:{
    centers(){
      return this.$store.getters['balance/allCenterList'];
    },
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    logs() {
      return this.$store.getters["balance/allLogs"];
    },
    rows() {
      return this.logs.length;
    }
  },
  async created(){
    await this.$store.dispatch('balance/getAllCenterList');
    await this.$store.dispatch('balance/getBalanceLogs',userService.methods.userID()).then( res =>{

    }).catch( err =>{
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
    });
    await this.$store.dispatch('user/getProfile').then( res =>{
        this.balance = res.student_data.balance;
    }).catch( err =>{
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
    });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div>
      <div class="row justify-content-center" >
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-4">
                <h2 class="card-title">
                    My balance <span class="badge bg-primary"> {{ balance }} </span>
                </h2>
              <form action="#" @submit.prevent="tryToLink">
                <div class="row justify-content-center" >
                    <div class="col-md-4">
                        <b-form-group label="Enter your code here" label-for="input-C" >
                          <input
                          class="form-control"
                          v-model="code"
                          type="text"
                          id="phoneid"
                          placeholder="Enter code"
                          :class="{ 'is-invalid': submitted && $v.code.$error }"
                          />
                          <div
                          v-if="submitted && $v.code.$error"
                          class="invalid-feedback"
                          >
                          <span v-if="!$v.code.required">Code is required.</span>
                          </div>
                        </b-form-group>
                    </div>
                    <div class="col-md-4" >
                      <b-form-group label="How did you get this code?" label-for="input-C" >
                        <b-form-select  v-model="center" :options="this.centers" :state="centerError" @change="clearSelectError2" text-field="name" value-field="id"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-12 text-center">
                        <b-button :disabled="loading" variant="success" pill type="submit" style="width: 15rem;">
                        Redeem
                        </b-button>
                    </div>
                    
                  </div>
                </form>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Display&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;Records
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center mt-2">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                    <b-dropdown
                      id="dropdown-offset"
                      offset="250"
                      variant="link"
                      class="btn-icon-only"
                    >
                      <template #button-content>
                        <i class="fas fa-th-large iconGray"></i>
                      </template>
                      <b-dropdown-form>
                        <b-form-checkbox
                          v-for="(field, index) in fields"
                          :key="index"
                          class="mb-3"
                          v-model="field.selected"
                          :disabled="field.disabled"
                          >{{ field.label }}</b-form-checkbox
                        >
                      </b-dropdown-form>
                    </b-dropdown>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <b-table
                table-class="table table-centered w-100 table-hover"
                thead-tr-class="bg-light"
                tbody-tr-class="clickableRow"
                :items="logs"
                :fields="tableFields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
              <template v-slot:cell(timestamp)="data">
                {{ data.item.timestamp | formatDate }}
              </template>
              <template v-slot:cell(balance_codes)="data">
                {{ data.item.balance_codes.code + ' - ' + data.item.balance_codes.balance_value }}
              </template>
              <template v-slot:cell(code_dropdown)="data">
                {{ data.item.code_dropdown.name }}
              </template>
              </b-table>
              <div class="row">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="(filter == '' )?rows:totalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
